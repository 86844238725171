<template>
  <div class="auther-analysis-container">
    <div class="topBox">
      <div class="tree" ref="tree"></div>
      <div class="keywords" ref="keywords"></div>
    </div>
    <div class="source" ref="source"></div>
    <div class="auther" ref="auther"></div>
    <div class="unit" ref="unit"></div>
    <Spin size="large" fix v-if="spinShow"></Spin>
  </div>
</template>

<script>
import * as echarts from "echarts";
import "echarts-wordcloud";
export default {
  data() {
    return {
      dataObj: {
        Year: [],
        Periodical: [],
        CoScholar: [],
        Mechanism: []
      },
      treeOption: {},
      keywordsOption: {},
      sourceOption: {},
      autherOption: {},
      unitOption: {},
      keywordsArr: [],
      spinShow: false
    };
  },
  created() {},
  mounted() {
    this.getEcharts({
      userId: this.$route.query.id,
    });
  },
  computed: {
    // noShow() {
    //   return this.dataObj.Year.length === 0 && this.keywordsArr.length === 0 && this.dataObj.Periodical.length === 0 && this.dataObj.CoScholar.length === 0 && this.dataObj.Mechanism.length === 0
    // }
  },
  methods: {
    async getEcharts(data) {
      this.spinShow = true
      const id = { id: data.userId };
      const resp = await this.$apis.autherServe.getEcharts(data);
      const resKeywords = await this.$apis.autherServe.getKeywords(id);
      this.keywordsArr = resKeywords.content.list.length > 0 ? [...resKeywords.content.list] : [{name: "无", value: "1"}];
      for (const key in this.dataObj) {
        this.dataObj[key] = resp.data[key].length > 0 ? [...resp.data[key]] : [{count: "0", value: ""}]
      }
      // this.dataObj = resp.data;
      this.treeFn();
      this.keywordsFn();
      this.sourceFn();
      this.autherFn();
      this.unitFn();
      this.spinShow = false
    },
    treeFn() {
      const treeChart = echarts.init(this.$refs.tree);
      this.treeOption = {
        tooltip: {},
        title: {
          text: "年成果数",
        },
        xAxis: {
          type: "category",
          boundaryGap: false,
          data: this.dataObj.Year.map((item) => item.value),
        },
        yAxis: {
          type: "value",
          minInterval: 1,
          min: 0,
          max: function(value) {//取最大值向上取整为最大刻度
            return value.max === 0 ? 10 : Math.ceil(value.max) 
          }
        },
        series: [
          {
            data: this.dataObj.Year.map((item) => item.count),
            type: "line",
            smooth: true,
          },
        ],
      };
      this.treeOption && treeChart.setOption(this.treeOption);
    },
    keywordsFn() {
      const keywodrsChart = echarts.init(this.$refs.keywords);
      this.keywordsOption = {
        title: {
          x: "20px",
          text: "研究领域",
        },
        series: [
          {
            type: "wordCloud",
            // shape: 'circle',
            // left: "",
            top: "6%",
            width: "100%",
            height: "90%",
            right: null,
            bottom: null,
            sizeRange: [20, 60],
            rotationRange: '90',
            rotationStep: 90,
            gridSize: 7,
            drawOutOfBound: false,
            layoutAnimation: true,
            // Global text style
            textStyle: {
              fontFamily: "sans-serif",
              fontWeight: "bold",
              // Color can be a callback function or a color string
              color: function () {
                // Random color
                return (
                  "rgb(" +
                  [
                    Math.round(Math.random() * 160),
                    Math.round(Math.random() * 160),
                    Math.round(Math.random() * 160),
                  ].join(",") +
                  ")"
                );
              },
            },
            emphasis: {
              focus: "self",

              textStyle: {
                textShadowBlur: 10,
                textShadowColor: "#333",
              },
            },

            // Data is an array. Each array item must have name and value property.
            data: this.keywordsArr,
          },
        ],
      };
      this.keywordsOption && keywodrsChart.setOption(this.keywordsOption);
    },
    sourceFn() {
      const sourceChart = echarts.init(this.$refs.source);
      this.sourceOption = {
        title: {
          text: "期刊来源",
        },
        xAxis: {
          minInterval: 1,
          min: 0,
          max: function(value) {//取最大值向上取整为最大刻度
            return value.max === 0 ? 10 : Math.ceil(value.max) 
          }
        },
        yAxis: {
          type: "category",
          data: this.dataObj.Periodical.map((item) => item.value),
          axisLabel:{
            show:true,
            formatter:function(value){
                var texts=value;
                if(texts.length>8){ // 具体多少字就看自己了
                    texts=texts.substr(0,8)+'...';
                }
                return texts;
            }
          }
        },
        grid:{
            top:48,
            left:120,// 调整这个属性
            right: 10,
            bottom:50,
        },
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "shadow"
          }
        },
        series: {
          type: "bar",
          data: this.dataObj.Periodical.map((item) => item.count),
          barMaxWidth: "30",
          barMinWidth: "20",
        },
      };
      this.sourceOption && sourceChart.setOption(this.sourceOption);
    },
    autherFn() {
      const autherChart = echarts.init(this.$refs.auther);
      this.autherOption = {
        tooltip: {},
        title: {
          text: "合作学者",
          top: "left",
          left: "top",
        },
        animationDuration: function (idx) {
            // 越往后的数据时长越大
            return idx * 100;
        },
        animationEasingUpdate: "quinticInOut",
        series: [
          {
            nmae: 'XXX',
            type: "graph",
            top: '20%',
            left: '20%',
            width: '50%',
            height: '70%',
            layout: "force",
            nodes: this.dataObj.CoScholar.map((item,index) => {
              return {
                id: item.authorId,
                name: item.value,
                symbolSize: index === 0 ? 50 : item.count >= 10 ? item.count : 10,
                value: item.count
              };
            }),
            links: this.dataObj.CoScholar.slice(1).map((item) => {
              return {
                source: item.authorId,
                target: this.dataObj.CoScholar[0].authorId,
              };
            }),
            label: {
              position: 'top'
            },
            lineStyle: {
              color: "source",
              curveness: 0.3,
            },
            emphasis: {
              focus: "adjacency",
              lineStyle: {
                width: 10,
              },
            },
            force: {
              repulsion: 100,
              gravity: 0.01,
              edgeLength: [50,100]
            },
          },
        ],
      };
      this.autherOption && autherChart.setOption(this.autherOption);
    },
    unitFn() {
      const unitChart = echarts.init(this.$refs.unit);
      this.unitOption = {
        title: {
          text: "合作机构",
        },
        xAxis: {
          minInterval: 1,
          min: 0,
          max: function(value) {//取最大值向上取整为最大刻度
            return value.max === 0 ? 10 : Math.ceil(value.max) 
          }
        },
        yAxis: {
          type: "category",
          data: this.dataObj.Mechanism.map((item) => item.value),
          axisLabel:{
            show:true,
            formatter:function(value){
                var texts=value;
                if(texts.length>8){ // 具体多少字就看自己了
                    texts=texts.substr(0,8)+'...';
                }
                return texts;
            }
          }
        },
        grid:{
            top:48,
            left:120,// 调整这个属性
            right: 10,
            bottom:50,
        },
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "shadow"
          }
        },
        series: {
          type: "bar",
          data: this.dataObj.Mechanism.map((item) => item.count),
          barMaxWidth: "30",
          barMinWidth: "20",
        },
      };
      this.unitOption && unitChart.setOption(this.unitOption);
    },
  },
};
</script>

<style lang="scss" scoped>
.auther-analysis-container {
  position: relative;
  overflow: hidden;
  margin-bottom: 111px;
  min-height: 800px;
  width: 1040px;
  border: 1px dotted #999999;
  border-radius: 12px;
  padding: 30px;
  p {
    text-align: center;
    font-size: 20px;
  }
  .topBox {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    background-color: #fff;
    .tree {
      width: 650px;
      height: 367px;
    }
    .keywords {
      width: 325px;
      height: 367px;
    }
  }
  .source {
    width: 100%;
    height: 446px;
    margin: 30px 0;
  }
  .auther {
    width: 418px;
    height: 332px;
    float: left;
  }
  .unit {
    width: 548px;
    height: 386px;
    float: left;
  }
}
</style>
